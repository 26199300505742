import DataAdapter from '../adapters/dataAdapter'

export function doLoginGoogle(mail, tokenID, callBack, callBackError) {
  let options = {
    body: JSON.stringify({
      mail: mail,
      tokenID: tokenID
    })
  }
  doLogin(options, callBack, callBackError)
}

export function doLoginEmail(mail, password, callBack, callBackError) {
  let options = {
    body: JSON.stringify({
      mail: mail,
      password: password
    })
  }
  doLogin(options, callBack, callBackError)
}

function doLogin(options, callBack, callBackError) {
  const dataAdapter = new DataAdapter()
  dataAdapter.doLogin(options,
    function (data) {
      // On Success:
      if (data.jwt) {
        localStorage.setItem('jwtToken', data.jwt)
      }
      callBack({ processed: data.processed, message: data.message })
    },

    function (error) {
      // On Error:
      callBackError({ processed: false, error: error.message })
    }
  )
}
export function loginMicrosoft(options, callBack, callBackError) {
  const dataAdapter = new DataAdapter()
  dataAdapter.MicrosoftLogin(options,
    function (data) {
      // On Success:
      if (data.jwt) {
        localStorage.setItem('jwtToken', data.jwt)
      }
      callBack({ processed: data.processed, message: data.message })
    },

    function (error) {
      // On Error:
      callBackError({ processed: false, error: error.message })
    }
  )
}
export function enviarCorreoCodigo(options, callBack, callBackError) {
  const dataAdapter = new DataAdapter()
  dataAdapter.generarTokenlogin(options,
    function (data) {
      // On Success:
      callBack({ processed: data.processed, message: data.message })
    },
    function (error) {
      // On Error:
      callBackError({ processed: false, message: error.message })
    }
  )
}
export function loginToken(options, callBack, callBackError) {
  const dataAdapter = new DataAdapter()
  dataAdapter.loginToken(options,
    function (data) {
      // On Success:

      if (data.processed) {
        if (data.jwt) {
          localStorage.setItem('jwtToken', data.jwt)
        }
        callBack({ processed: data.processed, message: data.message })
      } else {
        callBackError({ processed: false, message: data.message })
      }
    },
    function (error) {
      // On Error:
      callBackError({ processed: false, error: error.message })
    }
  )
}