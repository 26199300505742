export default function reducer(state = {
  unidadesDeNegocio: undefined,
  lastFetched: undefined
}, action) {
  switch (action.type) {
    case "LOAD_UNIDADES_DE_NEGOCIO": {
      state = { ...state, ...action.payload }
      break
    }
    default: {

    }
  }
  return state
}