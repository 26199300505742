export default function reducer(state = {
  datosRRHHTest: undefined,
  datosRRHHProduction: undefined
}, action) {
  switch (action.type) {
    case "SAVE_DATOS_RRHH_TEST": {
      state = { ...state, ...action.payload }
      break
    }
    case "SAVE_DATOS_RRHH_PRODUCTION": {
      state = { ...state, ...action.payload }
      break
    }
    default: {

    }
  }
  return state
}