import React from 'react'
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout'


function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
})

const Cierres = Loadable({
  loader: () => import('./views/Cierres'),
  loading: Loading,
})

const Reportes = Loadable({
  loader: () => import('./views/Reportes'),
  loading: Loading,
})

const AdiuvoInventario = Loadable({
  loader: () => import('./views/AdiuvoInventario'),
  loading: Loading,
})

const ReporteConsolidacionVentasCR = Loadable({
  loader: () => import('./views/ReporteConsolidacionVentasCR'),
  loading: Loading,
})

const RecursosHumanos = Loadable({
  loader: () => import('./views/RecursosHumanos'),
  loading: Loading,
})

const ReportesProconsumo = Loadable({
  loader: () => import('./views/ReportesProconsumo'),
  loading: Loading,
})

const MonitoreoPreciosCarga = Loadable({
  loader: () => import('./views/MonitoreoPreciosCarga'),
  loading: Loading,
})

const MonitoreoPreciosComparacion = Loadable({
  loader: () => import('./views/MonitoreoPreciosComparacion'),
  loading: Loading,
})

//Inicio correos CR

const CorreosRecibidos = Loadable({
  loader: () => import('./views/Correos'),
  loading: Loading,
})

const CorreosAsignados = Loadable({
  loader: () => import('./views/CorreosAsignados'),
  loading: Loading,
})

const CorreosAsignadosAOtros = Loadable({
  loader: () => import('./views/CorreosAsignadosAOtros'),
  loading: Loading,
})

const CorreosSinAceptar = Loadable({
  loader: () => import('./views/CorreosSinAceptar'),
  loading: Loading,
})

const CierreDeCompras = Loadable({
  loader: () => import('./views/CierreDeCompras'),
  loading: Loading,
})

const CorreosArchivados = Loadable({
  loader: () => import('./views/CorreosArchivados'),
  loading: Loading,
})

const BusquedaCorreos = Loadable({
  loader: () => import('./views/BusquedaCorreos'),
  loading: Loading,
})

const CorreosMoverFacturas = Loadable({
  loader: () => import('./views/CorreosMoverFacturas'),
  loading: Loading,
})

const CorreosEstadisticas = Loadable({
  loader: () => import('./views/CorreosEstadisticas'),
  loading: Loading,
})

const CorreosTrabajados = Loadable({
  loader: () => import('./views/CorreosTrabajados'),
  loading: Loading,
})

// Fin correos CR

// Inicio correos DE

const CorreosAsignadosDE = Loadable({
  loader: () => import('./views/CorreosAsignadosDE'),
  loading: Loading,
})

const CorreosArchivadosDE = Loadable({
  loader: () => import('./views/CorreosArchivadosDE'),
  loading: Loading,
})

const CierreDeComprasDE = Loadable({
  loader: () => import('./views/CierreDeComprasDE'),
  loading: Loading,
})

const BusquedaCorreosDE = Loadable({
  loader: () => import('./views/BusquedaCorreosDE'),
  loading: Loading,
})

const CorreosTrabajadosDE = Loadable({
  loader: () => import('./views/CorreosTrabajadosDE'),
  loading: Loading,
})

// Fin correos DE

const Parapedidos = Loadable({
  loader: () => import('./views/Parapedidos'),
  loading: Loading,
})

const ParapedidosPorAprobar = Loadable({
  loader: () => import('./views/ParapedidosPorAprobar'),
  loading: Loading,
})

const ProveedoresPedidosPendientesAprobar = Loadable({
  loader: () => import('./views/ProveedoresPedidosPendientesAprobar'),
  loading: Loading,
})

const EstadoServidoresSucursales = Loadable({
  loader: () => import('./views/EstadoServidoresSucursales'),
  loading: Loading,
})

const administracionUsuarios = Loadable({
  loader: () => import('./views/AdministracionUsuarios'),
  loading: Loading,
})

const UsuariosCrear = Loadable({
  loader: () => import('./views/UsuariosCrear'),
  loading: Loading,
})

const UsuariosEditar = Loadable({
  loader: () => import('./views/UsuariosEditar'),
  loading: Loading,
})

const ListadoDePedidosEnLinea = Loadable({
  loader: () => import('./views/ListadoDePedidosEnLinea'),
  loading: Loading,
})

const CuponesRedimidos = Loadable({
  loader: () => import('./views/CuponesRedimidos'),
  loading: Loading,
})

const CuponesInventario = Loadable({
  loader: () => import('./views/CuponesInventario'),
  loading: Loading,
})

const Healthcheck = Loadable({
  loader: () => import('./views/Healthcheck'),
  loading: Loading,
})
const RecurrenciasMensuales = Loadable({
  loader: () => import('./views/RecurrenciasMensuales'),
  loading: Loading,
})

const BaseConocimiento = Loadable({
  loader: () => import('./views/BaseConocimiento'),
  loading: Loading,
})

const Evaluaciones = Loadable({
  loader: () => import('./views/Evaluaciones'),
  loading: Loading,
})

const ErroresApp = Loadable({
  loader: () => import('./views/ErroresApp'),
  loading: Loading,
})

const Csvs = Loadable({
  loader: () => import('./views/Csvs'),
  loading: Loading,
})

const CuponesCarga = Loadable({
  loader: () => import('./views/CuponesCarga'),
  loading: Loading,
})

const Paquetes = Loadable({
  loader: () => import('./views/Paquetes'),
  loading: Loading,
})

const Premios = Loadable({
  loader: () => import('./views/Premios'),
  loading: Loading,
})

const Audios = Loadable({
  loader: () => import('./views/Audios'),
  loading: Loading,
})


const Wiki = Loadable({
  loader: () => import('./views/Wiki'),
  loading: Loading,
})

const PedidosImagen = Loadable({
  loader: () => import('./views/PedidosImagen'),
  loading: Loading,
})

const UbicacionMotoristas = Loadable({
  loader: () => import('./views/UbicacionMotoristas'),
  loading: Loading,
})

const MotoristasRetroalimentacionUbicaciones = Loadable({
  loader: () => import('./views/MotoristasRetroalimentacionUbicaciones'),
  loading: Loading,
})

const MotoristasEntregas = Loadable({
  loader: () => import('./views/MotoristasEntregas'),
  loading: Loading,
})

const MapaCalorEntregas = Loadable({
  loader: () => import('./views/MapaCalorEntregas'),
  loading: Loading,
})

const Grabaciones = Loadable({
  loader: () => import('./views/Grabaciones'),
  loading: Loading,
})

const EstadisticasApp = Loadable({
  loader: () => import('./views/EstadisticasApp'),
  loading: Loading,
})

const HerramientaPushNotifications = Loadable({
  loader: () => import('./views/HerramientaPushNotifications'),
  loading: Loading,
})

const HerramientaSMS = Loadable({
  loader: () => import('./views/HerramientaSMS'),
  loading: Loading,
})

const ParametrosApp = Loadable({
  loader: () => import('./views/ParametrosApp'),
  loading: Loading,
})

const ListOrdenIncompleta = Loadable({
  loader: () => import('./views/OrdenesIncompletas/'),
  loading: Loading,
})

const MailMasivo = Loadable({
  loader: () => import('./views/MailMasivo'),
  loading: Loading,
})
const WhatsappMasivo = Loadable({
  loader: () => import('./views/WhatsappMasivo'),
  loading: Loading,
})

const MotoristaViajesAsignados = Loadable({
  loader: () => import('./views/MotoristaViajesAsignados/'),
  loading: Loading,
})
const MotoristasSmsLog = Loadable({
  loader: () => import('./views/MotoristasSmsLog/'),
  loading: Loading,
})

const SincronizacionApp = Loadable({
  loader: () => import('./views/SincronizacionApp'),
  loading: Loading,
})
const healthcheckAdmin = Loadable({
  loader: () => import('./views/healthcheckAdmin'),
  loading: Loading,
})

const ParapedidosLogs = Loadable({
  loader: () => import('./views/ParapedidosLogs'),
  loading: Loading,
})
const ParapedidosDashboard = Loadable({
  loader: () => import('./views/ParapedidosDashboard'),
  loading: Loading,
})

const ValidarCorreos = Loadable({
  loader: () => import('./views/ValidarCorreos/'),
  loading: Loading,
})
const ListaDeLotesPorValidar = Loadable({
  loader: () => import('./views/ListaDeLotesPorValidar/'),
  loading: Loading,
})
const ResultadoCorreosLote = Loadable({
  loader: () => import('./views/ResultadoCorreosLote'),
  loading: Loading,
})
const ListaDeCorreosValidados = Loadable({
  loader: () => import('./views/ListaDeCorreosValidados'),
  loading: Loading,
})

const PushNotificationLogs = Loadable({
  loader: () => import('./views/PushNotifiationLogs'),
  loading: Loading,
})

const Reclutamiento = Loadable({
  loader: () => import('./views/Reclutamiento'),
  loading: Loading,
})
const ReporteParametrosApp = Loadable({
  loader: () => import('./views/ReporteAppParametrosPaises'),
  loading: Loading,
})
const ReporteConsultaClientesPrecio = Loadable({
  loader: () => import('./views/ReporteConsultaClientesPrecio'),
  loading: Loading,
})

const ReporteProductosPorPais = Loadable({
  loader: () => import('./views/ReporteProductoPorPais'),
  loading: Loading,
})

const GruposTeams = Loadable({
  loader: () => import('./views/GruposTeams'),
  loading: Loading,
})
const WsProveedores = Loadable({
  loader: () => import('./views/WsProveedores'),
  loading: Loading,
})
const ItemsProveedor = Loadable({
  loader: () => import('./views/ItemsProveedor'),
  loading: Loading,
})
const WsProveedoresUsuario = Loadable({
  loader: () => import('./views/WsProveedoresUsuario/'),
  loading: Loading,
})
const ReportesApp = Loadable({
  loader: () => import('./views/ReportesApp'),
  loading: Loading,
})
const CuponesBuscar = Loadable({
  loader: () => import('./views/BuscarCupon'),
  loading: Loading,
})

const CuponesCanjes = Loadable({
  loader: () => import('./views/CuponCanje'),
  loading: Loading,
})

const LinkPublicidad = Loadable({
  loader: () => import('./views/LinkPublicidad'),
  loading: Loading,
})
const LogsCargaImagenes = Loadable({
  loader: () => import('./views/CargarImagenes'),
  loading: Loading,
})

const EstadisticaEnlacesPublicidad = Loadable({
  loader: () => import('./views/EstadisticaEnlacesPublicidad'),
  loading: Loading,
})
const TokenPushNotificacionUsuario = Loadable({
  loader: () => import('./views/TokenPushNotificacionUsuario'),
  loading: Loading,
})
const BitacoraSms = Loadable({
  loader: () => import('./views/EnvioSmsLogs'),
  loading: Loading,
})
const PlantillasCorreo = Loadable({
  loader: () => import('./views/PlantillasCorreo'),
  loading: Loading,
})

const PedidosReportes = Loadable({
  loader: () => import('./views/PedidosReportes/PedidosReportes'),
  loading: Loading,
})

const UsuarioAsignarHealthcheck = Loadable({
  loader: () => import('./views/UsuarioAsignarHealthcheck'),
  loading: Loading,
})

const routes = [
  { path: '/', exact: true, name: 'Inicio', component: DefaultLayout },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/cierres/menu', name: 'Cierres', component: Cierres },
  { path: '/reportes/menu', name: 'Reportes', component: Reportes },
  { path: '/adiuvo/ventas', name: 'Adiuvo - Venta e Inventario', component: AdiuvoInventario },
  { path: '/consolidacion-ventas/CR', name: 'Consolidación de Ventas - Costa Rica', component: ReporteConsolidacionVentasCR },
  { path: '/rrhh/:tipo', name: 'Recursos Humanos', component: RecursosHumanos }, //tipo: test o production
  { path: '/proconsumo/reportes', name: 'Reportes Proconsumo', component: ReportesProconsumo },
  { path: '/monitoreo-precios/carga', name: 'Monitoreo de Precios - Carga', component: MonitoreoPreciosCarga },
  { path: '/monitoreo-precios/comparacion', name: 'Monitoreo de Precios - Comparación', component: MonitoreoPreciosComparacion },

  // Inicio correos CR

  { path: '/correos/recibidos', name: 'Correos', component: CorreosRecibidos },
  { path: '/correos/asignados', name: 'Correos Asignados', component: CorreosAsignados },
  { path: '/correos/asignados-a-otros', name: 'Correos Asignados a Otros', component: CorreosAsignadosAOtros },
  { path: '/correos/sin-aceptar', name: 'Facturas Sin Aceptar', component: CorreosSinAceptar },
  { path: '/correos/cierre-compras', name: 'Cierre de Compras', component: CierreDeCompras },
  { path: '/correos/archivados', name: 'Correos Archivados', component: CorreosArchivados },
  { path: '/correos/busqueda', name: 'Búsqueda de Facturas', component: BusquedaCorreos },
  { path: '/correos/mover-facturas', name: 'Facturas Aceptadas - Cambio de Sucursal', component: CorreosMoverFacturas },
  { path: '/correos/trabajados', name: 'Facturas Trabajadas', component: CorreosTrabajados },
  { path: '/correos/estadisticas', name: 'Estadísticas', component: CorreosEstadisticas },

  //Fin correos CR

  // Inicio correos DE

  { path: '/de/correos/asignados', name: 'Correos Asignados - Droguería Europea', component: CorreosAsignadosDE },
  { path: '/de/correos/archivados', name: 'Correos Archivados - Droguería Europea', component: CorreosArchivadosDE },
  { path: '/de/correos/cierre-compras', name: 'Cierre de Compras - Droguería Europea', component: CierreDeComprasDE },
  { path: '/de/correos/busqueda', name: 'Búsqueda de Facturas - Droguería Europea', component: BusquedaCorreosDE },
  { path: '/de/correos/trabajados', name: 'Facturas Trabajadas - Droguería Europea', component: CorreosTrabajadosDE },

  // Fin correos DE


  { path: '/estado-servidores-sucursales', name: 'Estado Servidores Sucursales', component: EstadoServidoresSucursales },
  { path: '/administracion/usuarios', name: 'Administración - Usuarios', component: administracionUsuarios },
  { path: '/usuario/crear', name: 'Usuario - Crear', component: UsuariosCrear },
  { path: '/usuario/editar/:id', name: 'Usuario - Editar', component: UsuariosEditar },

  //Pedidos en linea
  //Pedidos en linea
  { path: '/reporte/reporte_pedidos_en_linea', name: 'Pedidos', component: ListadoDePedidosEnLinea },
  { path: '/parapedidos/por-confirmar', name: 'Pedidos', component: Parapedidos },
  { path: '/parapedidos/logs', name: 'Pedidos en linea Logs', component: ParapedidosLogs },
  { path: '/parapedidos/proveedores/pedidos-pendientes-aprobar', name: 'Proveedores con Pedidos Pendientes de Aprobar', component: ProveedoresPedidosPendientesAprobar },
  { path: '/parapedidos/por-aprobar/:proveedorId/:proveedorNombre/:unidadNegocio', name: 'Pedidos por Aprobar', component: ParapedidosPorAprobar },
  { path: '/parapedidos/dashboard/:permiso', name: 'Pedidos en linea Dashboard', component: ParapedidosDashboard },
  { path: '/parapedidos/reporte/ordenes', name: 'Pedidos Reportes', component: PedidosReportes },
  //Pedidos en linea
  //Pedidos en linea

  // Inicio Cupones

  { path: '/cupones/redimidos', name: 'Cupones Redimidos', component: CuponesRedimidos },
  { path: '/cupones/inventario', name: 'Inventario de Cupones', component: CuponesInventario },
  { path: '/cupones/carga', name: 'Cupones - Carga', component: CuponesCarga },
  { path: '/cupones/buscar', name: 'Cupones - Busqueda', component: CuponesBuscar },
  { path: '/cupones/canjes', name: 'Cupones -Canjes', component: CuponesCanjes },


  { path: '/listar-ws-proveedores', name: 'Listado de Proveedores', component: WsProveedores },
  { path: '/asignación-items-proveedor', name: 'Asignación de Items Proveedor', component: ItemsProveedor },
  { path: '/proveedores-usuario', name: 'Asignación de proveedores a usuario', component: WsProveedoresUsuario },

  // Fin Cupones

  // Inicio Notificaciones

  { path: '/notificaciones/herramienta-push-notifications', name: 'Herramienta Push Notifications', component: HerramientaPushNotifications },
  { path: '/notificaciones/listado-push-notificaciones', name: 'Lista de Push Notifications enviadas', component: PushNotificationLogs },
  { path: '/notificaciones/herramienta-sms', name: 'Herramienta SMS', component: HerramientaSMS },
  { path: '/notificaciones/bitacora-sms', name: 'Bitacora Sms', component: BitacoraSms },

  // Fin Notificaciones
  //Reclutamiento
  { path: '/reclutamiento/vacantes', name: 'Reclutamiento Vacantes', component: Reclutamiento },
  //Reclutamiento


  { path: '/healthcheck/', name: 'Healthcheck', component: Healthcheck },
  { path: '/usuario/asignar/healthcheck/:id', name: 'Usuario - Asignar - Healthcheck', component: UsuarioAsignarHealthcheck },
  { path: '/ventas/recurrencias/mensuales', name: 'Recurrencias Mensuales', component: RecurrenciasMensuales },
  { path: '/ventas/errores-app', name: 'Errores App', component: ErroresApp },
  { path: '/helpdesk/base-conocimiento', name: 'Base de Conocimiento', component: BaseConocimiento },
  { path: '/evaluaciones/listado-evaluaciones', name: 'Evaluaciones', component: Evaluaciones },
  { path: '/reporte/listado-ordenes-incompletas', name: 'Ordenes Incompletas', component: ListOrdenIncompleta },
  { path: '/csv/url', name: 'Csvs', component: Csvs },
  { path: '/paquetes/', name: 'Paquetes', component: Paquetes },
  { path: '/premio/clientes', name: 'Premios', component: Premios },
  { path: '/audio/url', name: 'Audios', component: Audios },
  { path: '/wiki/acceder', name: 'Wiki', component: Wiki },
  { path: '/reporte/pedidos-imagen', name: 'Pedidos - Imagen', component: PedidosImagen },
  { path: '/motorista/posiciones', name: 'Ubicación Motoristas', component: UbicacionMotoristas },
  { path: '/motorista/retroalimentacion-ubicaciones', name: 'Motoristas - Retroalimentación de Ubicaciones', component: MotoristasRetroalimentacionUbicaciones },
  { path: '/motorista/entregas', name: 'Entregas', component: MotoristasEntregas },
  { path: '/motorista/mapa-calor-entregas', name: 'Mapa - Calor - Entregas', component: MapaCalorEntregas },
  { path: '/motorista/Viajes-asignados', name: 'Motorista Viajes Asignados', component: MotoristaViajesAsignados },
  { path: '/motorista/Sms-log', name: 'Motorista Sms Logs', component: MotoristasSmsLog },
  { path: '/healthcheckAdmin/:id', name: 'Administrar Healthcheck', component: healthcheckAdmin },


  { path: '/grabaciones', name: 'Grabaciones', component: Grabaciones },
  { path: '/reporte/estadisticas-app', name: 'Estadisticasa - App', component: EstadisticasApp },
  { path: '/parametro/listar', name: 'Parámetros - App', component: ParametrosApp },
  { path: '/listar-parametros-app-paises', name: 'Lista de parametros app por país.', component: ReporteParametrosApp },
  { path: '/listar-consultas-clientes-precio', name: 'Reporte de consultas de precios por cliente', component: ReporteConsultaClientesPrecio },
  { path: '/listar-productos-pais', name: 'Reporte de Productos', component: ReporteProductosPorPais },
  // gurpos Teams rutas
  { path: '/listar-grupos-teams', name: 'Listado de grupos teams', component: GruposTeams },
  // gurpos Teams rutas

  { path: '/app/sincronizacion', name: 'App - Sincronizacion', component: SincronizacionApp },
  //Herramienta de publicidad
  //Herramienta de publicidad
  { path: '/Mail/Masivo', name: 'Mail Masivo ', component: MailMasivo },
  { path: '/Whatsapp/Masivo', name: 'Whatsapp Masivo ', component: WhatsappMasivo },
  { path: '/Plantillas/correo', name: 'Plantillas Correos ', component: PlantillasCorreo },
  //Herramienta de publicidad
  //Herramienta de publicidad

  //Validación de correos
  { path: '/Validacion/correo-electronico', name: 'Validar Correos', component: ValidarCorreos },
  { path: '/Validacion/Lista-de-lotes', name: 'Revisión de lista de correos ', component: ListaDeLotesPorValidar },
  { path: '/Validacion/Correos-validados-lote/:id/:unidad', name: 'Correos Validados por lote ', component: ResultadoCorreosLote },
  { path: '/Validacion/Lista-correos-validos/', name: 'Lista de correos validados ', component: ListaDeCorreosValidados },
  //Validación de correos

  //link publicidad
  { path: '/publicidad/links', name: 'Enlaces Publicidad', component: LinkPublicidad },
  { path: '/publiciad/links/estadistica/:id/:unidad', name: 'Estadisticas enlace de publicidad', component: EstadisticaEnlacesPublicidad },
  //link publicidad

  //logs carga imagen
  { path: '/app/carga-imagen', name: 'App - Cargar Imagenes', component: LogsCargaImagenes },
  //logs carga imagen

  // Reportes app
  { path: '/reportes/App', name: 'Reportes App', component: ReportesApp },
  // Reportes app
  // Cliente Token push notificacion
  { path: '/cliente/token-push-cliente', name: 'Token push notificación cliente', component: TokenPushNotificacionUsuario },
  // Reportes app
]

export default routes
