export default function reducer(state = {
  reportes: [],
  sessions: []
}, action) {
  switch (action.type) {
      case "SAVE_REPORTES": {
          state = { ...state, ...action.payload }
          break
      }
      case "UPDATE_REPORTS_SESSIONS": {
        state = { ...state, ...action.payload }
        break
    }
      default: {

      }
  }
  return state
}