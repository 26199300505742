export function saveCierres(cierres) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_CIERRES",
      payload: {
        cierres: cierres,
        lastFetched: Date.now()
      }
    })
  }
}