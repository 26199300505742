import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import ClearCache from 'react-clear-cache'

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import { loggedIn } from '../../utils/session.js'

class DefaultLayout extends Component {

  state = {
    menu: this.props.menu
  }

  componentWillMount = () => {
    if (!loggedIn()) {
      this.props.history.push("/login")
    }
  }

  componentDidMount = () => {
    setTimeout(function () {
      let clearCache = document.getElementById("clear-cache")
      if (clearCache) {
        clearCache.click()
      }
    }, 5000)
  }

  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={this.state.menu} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <ClearCache>
                {({ isLatestVersion, emptyCacheStorage }) =>

                  <div>
                    {!isLatestVersion && (

                      <p>
                        <a
                          id="clear-cache"
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            emptyCacheStorage();
                          }}
                        >
                          Actualizando a nueva versión...
                </a>
                      </p>
                    )}
                  </div>
                }
              </ClearCache>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component {...props} />
                  )} />)
                    : (null);
                },
                )}
                <Redirect from="/" to="/login" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu: state.menuSettings.menu.menuFinal
  }
}
export default connect(mapStateToProps)(DefaultLayout)
