import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'

import './index.css'
import App from './App'

import { Provider } from 'react-redux'
import store from '../src/store/store'

// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, document.getElementById('root'));
// disable ServiceWorker
// registerServiceWorker();
