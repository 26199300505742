export function saveReportes(reportes) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_REPORTES",
      payload: {
        reportes: reportes
      }
    })
  }
}

export function updateSessions(sessions) {
  return function (dispatch) {
    dispatch({
      type: "UPDATE_REPORTS_SESSIONS",
      payload: {
        sessions: sessions
      }
    })
  }
}