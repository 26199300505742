import DataAdapter from '../adapters/dataAdapter'

export function fetchFormatos(formatos, callBack, callBackError) {
  return function (dispatch) {
    const dataAdapter = new DataAdapter()
    dataAdapter.getFormatos(formatos,
      (data) => fetchFormatosSuccess(callBack, dispatch, data),
      (error) => callBackError(error)
    )
  }
}

function fetchFormatosSuccess(callBack, dispatch, data) {
  callBack()
  if (data) {
    dispatch({
      type: "LOAD_FORMATOS",
      payload: {
        formatos: data.formatos,
        lastFetched: Date.now()
      }
    })
  }
}