import { combineReducers } from 'redux'

// Redux Reducers
import userSettings from '../reducers/userSettingsReducer'
import menuSettings from '../reducers/menuSettingsReducer'
import cierres from '../reducers/cierresReducer'
import reportes from '../reducers/reportesReducer'
import rrhh from '../reducers/rrhhReducer'
import formatos from '../reducers/formatosReducer'
import reportesProconsumo from '../reducers/reportesProconsumoReducer'
import sucursales from '../reducers/sucursalesReducer'
import proveedoresPorConfirmar from '../reducers/proveedoresPorConfirmarReducer'
import helpdesk from '../reducers/helpdeskReducer'
import proveedores from '../reducers/proveedoresReducer'
import unidadesDeNegocio from '../reducers/unidadesDeNegocioReducer'
import emoticones from '../reducers/emoticonesReducer'
import motoristas from '../reducers/motoristasReducer'
import grabacionCriterios from '../reducers/grabacionCriteriosReducer'
import sucursalesHN from '../reducers/sucursalesHNReducer'
import sucursalesFE from '../reducers/sucursalesFEReducer'
import sucursalesCR from '../reducers/sucursalesCRReducer'
import sucursalesFD from '../reducers/sucursalesFDReducer'
import sucursalesFM from '../reducers/sucursalesFMReducer'
import sucursalesFV from '../reducers/sucursalesFVReducer'
import sucursalesFP from '../reducers/sucursalesFPReducer'
import sucursalesFG from '../reducers/sucursalesFGReducer'
import app from '../reducers/appReducer'

const appReducer = combineReducers({
    userSettings,
    menuSettings,
    cierres,
    reportes,
    rrhh,
    formatos,
    reportesProconsumo,
    sucursales,
    proveedoresPorConfirmar,
    helpdesk,
    proveedores,
    unidadesDeNegocio,
    emoticones,
    motoristas,
    grabacionCriterios,
    sucursalesHN,
    sucursalesFE,
    sucursalesCR,
    sucursalesFD,
    sucursalesFG,
    sucursalesFM,
    sucursalesFP,
    sucursalesFV,
    app
})
const rootReducer = (state, action) => {
    if (action.type === "LOG_OUT") {
        state = undefined;
    }

    return appReducer(state, action)
}
export default rootReducer

