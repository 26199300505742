import React, { Component } from 'react'
import { Card, CardBody, CardGroup, Col, Container, Row, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button, Alert } from 'reactstrap'
import { GoogleLogin } from 'react-google-login'

import { googleSignInWebClientID, habilitarLoginPassword, microsoftClienteId } from '../../../config/config.js'
import { doLoginGoogle, doLoginEmail, loginMicrosoft, enviarCorreoCodigo, loginToken } from '../../../actions/authActions'


import { saveUser } from "../../../actions/userSettingsActions"
import { saveMenu } from "../../../actions/menuSettingsActions"
import { saveCierres } from "../../../actions/cierresActions"
import { saveReportes } from "../../../actions/reportesActions"
import { saveDatosRRHHTest, saveDatosRRHHProduction } from "../../../actions/rrhhActions"
import { fetchFormatos } from "../../../actions/formatosActions"
import { saveDatosReportesProconsumo } from "../../../actions/reportesProconsumoActions"
import { saveDatosHelpdesk } from "../../../actions/helpdeskActions"

import { connect } from 'react-redux'

import DataAdapter from '../../../adapters/dataAdapter.js'

import '../../../css/defaultLayout.css'
import { loggedIn } from '../../../utils/session.js'

import ReactLoading from 'react-loading'
import MicrosoftLogin from "react-microsoft-login";

class Login extends Component {

  state = {
    nombre: "",
    mail: "",
    avatar: "",
    waiting: false,
    mmCorreo: "",
    mmCodigo: "",
    password: "",
    errorEmailPassword: false,
    errorEmailPasswordText: "",
    habilitarLoginPassword: habilitarLoginPassword,
    error: 0,
    modal: false,
    modalBody: "",
    modalConfirmacion: false,
    modalBodyConfirmacion: "",
    correoToken: "",
    codigoToken: "",
    ingresandoPorCodigo: ""
  }
  componentWillMount = () => {
    sessionStorage.clear()
    if (loggedIn()) {
      this.props.history.push("/dashboard") //todo: definir donde irá
    }

  }

  enviarCorreo = (e) => {
    e.preventDefault();
    this.setState({
      waiting: true,
      error: true,
      mmCorreo: "",
    })
    let options = {
      body: JSON.stringify({
        correo: this.state.correoToken
      })
    }
    enviarCorreoCodigo(options, this.enviarCorresoSuccess, this.enviarCorresoSuccess)
  }

  enviarCorresoSuccess = (response) => {
    this.setState({
      waiting: false,
      error: false,
      mmCorreo: response.message,
    })
  }

  ingresarPorCodigo = (e) => {
    e.preventDefault();
    this.setState({
      waiting: true,
      error: true,
      mmCodigo: "",
      mail: this.state.correoToken,
      avatar: "",
    })

    let options = {
      body: JSON.stringify({
        correo: this.state.correoToken,
        codigo: this.state.codigoToken,
      })
    }
    loginToken(options, this.handleDoLoginResponse, this.logintokenError)
  }
  logintokenError = (response) => {
    if (!response.processed) {
      this.setState({
        waiting: false,
        error: false,
        mmCodigo: response.message
      })
    }
  }


  LoginMicrosoft = (err, data) => {
    console.log(err)
    console.log(data)
    sessionStorage.clear()
    if (err == null) {
      this.setState({
        nombre: "",
        mail: data.account.userName,
        avatar: "",
        waiting: true,
        error: true,
      })
      let options = {
        body: JSON.stringify({
          mail: data.account.userName,
          token: data.idToken.rawIdToken,
          identificador: data.account.accountIdentifier,
          tenantId: data.tenantId,
          iss: data.idTokenClaims.iss
        })
      }
      loginMicrosoft(options, this.handleDoLoginResponse, this.handleGoogleRequestError)
    } else {
      sessionStorage.clear()
      console.log("Se ha presentado un error al acceder a su cuenta favor comuniquese con el administrador portal o intente más tarde.")
    }
  };

  handleChangeEmailtoken = (e) => {
    this.setState({ correoToken: e.target.value })
  }
  handleChangeCodigoToken = (e) => {
    this.setState({ codigoToken: e.target.value })
  }


  handleChangeEmail = (e) => {
    this.setState({ mail: e.target.value })
  }

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  setErrorEmailPassword = (errorEmailPassword) => {
    this.setState({ errorEmailPassword: errorEmailPassword })
  }

  handleSuccessGoogleSignIn = (response) => {
    let email = response.profileObj.email
    let tokenID = response.tokenId
    let avatar = response.profileObj.imageUrl

    this.setState({
      nombre: "",
      mail: email,
      avatar: avatar,
      waiting: true,
      error: true,
    })

    doLoginGoogle(email, tokenID, this.handleDoLoginResponse, this.handleGoogleRequestError)
  }

  handleDoLoginResponse = (response) => {
    this.setState({
      mmCodigo: response.message,
      ingresandoPorCodigo: 1
    })
    console.log(response)
    if (response.processed) {
      //guardar en storage info del usuario
      let user = {
        mail: this.state.mail,
        avatar: this.state.avatar
      }
      this.props.dispatch(saveUser(user))

      this.getMenu()

    } else {
      let mensaje = "Error al realizar la solicitud del código"
      if (response.error == "NOT_FOUND_MAIL" || response.message == "NOT_FOUND_MAIL") {
        // mensaje = "Tu correo no fué encontrado. Favor revísalo o pasa por cualquiera de nuestras sucursales registrándolo"
        // El correo del cliene no fue encontrado. Vamos a vaciar el mensaje de error para que no haga el popup
        mensaje = "Tu correo no esta permitido para esta aplicación"
      } else if (response.error == "NOT_VALID_GOOGLE_TOKEN" || response.message == "NOT_VALID_GOOGLE_TOKEN") {
        mensaje = "Tu token de Google no es válido o ha expirado"
      }

      if (mensaje !== "") {
        console.log(mensaje)
        this.setState({
          modalMensaje: true,
          modalBodyMensaje: mensaje
        })
      }
      this.setState({ waiting: false })
    }
  }

  handleGoogleRequestError = (error, mensaje) => {
    sessionStorage.clear()
    this.setState({
      waiting: false,
      modalMensaje: true,
      modalBodyMensaje: "Error al realizar solicitud, favor espere unos minutos y reintente"
    })
    console.log(error, mensaje)

  }

  handleErrorGoogleSignIn = (response) => {
    // solo mostrara erroes cuando sean distintos al erro 12501 que se produce cuando el usuario cierra la ventana de autenticacion con google
    if (response !== "12501") {
      let mensaje = "Por los momentos Google SingIn no esta activo. Favor reintente en otro momento. " + response.error
      console.log(mensaje)
      this.setState({
        modalMensaje: true,
        modalBodyMensaje: mensaje
      })
    }

  }

  doLoginEmailPassword = () => {
    this.setState({ waiting: true })
    doLoginEmail(this.state.mail, this.state.password, this.handleDoLoginEmailResponse, this.handleEmailRequestError)
  }

  handleDoLoginEmailResponse = (response) => {
    if (response.processed) {
      //guardar en storage info del usuario
      let user = {
        mail: this.state.mail,
        avatar: ""
      }
      this.props.dispatch(saveUser(user))

      this.getMenu()

    } else {
      let mensaje = "Error al realizar la solicitud"
      if (response.error == "NOT_FOUND_MAIL" || response.message == "NOT_FOUND_MAIL") {
        // mensaje = "Tu correo no fué encontrado. Favor revísalo o pasa por cualquiera de nuestras sucursales registrándolo"
        // El correo del cliene no fue encontrado. Vamos a vaciar el mensaje de error para que no haga el popup
        mensaje = "Usuario o contraseña inválidas, favor verifique los datos ingresados"
      }

      if (mensaje !== "") {
        this.setState({ errorEmailPassword: true, errorEmailPasswordText: mensaje })
      }
      this.setState({ waiting: false })
    }
  }

  handleEmailRequestError = (error, mensaje) => {
    this.setState({
      waiting: false,
      errorEmailPassword: true,
      errorEmailPasswordText: "Error al realizar solicitud, favor espere unos minutos y reintente"
    })
    console.log(error, mensaje)
  }

  toggleModalMensaje = () => {
    this.setState({
      mmCodigo: "",
      mmCorreo: "",
      modalMensaje: !this.state.modalMensaje,
    })
  }

  getMenu = () => {
    const dataAdapter = new DataAdapter()
    dataAdapter.getMenu(this.getMenuSuccess, this.getMenuError)
  }

  getMenuSuccess = (res) => {
    let menu = [...res.permisos]

    if (res.reportes && res.reportes.length > 0) {
      this.guardarReportesAutorizados(res.reportes)
    }

    if (res.reportes_proconsumo.processed) {
      this.guardarDatosReportesProconsumo({ usuario: res.reportes_proconsumo.user, clave: res.reportes_proconsumo.password })
    }
    if (res.cierres && res.cierres.length > 0) {
      this.guardarCierresAutorizados(res.cierres)
    }

    if (res.rrhh_test.processed || res.rrhh_production.processed) {
      if (res.rrhh_test.processed)
        this.guardarDatosRRHHTest({ usuario: res.rrhh_test.user, clave: res.rrhh_test.password })
      if (res.rrhh_production.processed)
        this.guardarDatosRRHHProduction({ usuario: res.rrhh_production.user, clave: res.rrhh_production.password })
    }

    if (res.helpdesk.processed) {
      this.guardarDatosHelpdesk({ usuario: res.helpdesk.user, clave: res.helpdesk.password })
    }

    let menuFinal = { items: menu }

    //guardar en storage menu
    let menuStorage = { menuFinal: menuFinal }
    this.props.dispatch(saveMenu(menuStorage))
    this.getFormatos()
    this.props.history.push(`/dashboard`)
  }

  crearMenuParapedidos = () => {
    let parapedidos = {
      name: 'Pedidos',
      url: '/parapedidos/por-confirmar',
      icon: 'fa fa-truck'
    }
    return parapedidos
  }

  crearMenuParapedidosPorAprobar = () => {
    let parapedidos = {
      name: 'Pedidos Por Aprobar',
      url: '/parapedidos/proveedores/pedidos-pendientes-aprobar',
      icon: 'fa fa-check-square-o'
    }
    return parapedidos
  }

  guardarCierresAutorizados = (cierresItems) => {
    this.props.dispatch(saveCierres(cierresItems))
  }

  guardarReportesAutorizados = (reportesItems) => {
    let reportesAsc = reportesItems.sort(this.compare)
    this.props.dispatch(saveReportes(reportesAsc))
  }

  guardarDatosRRHHTest = (datosRRHHTest) => {
    this.props.dispatch(saveDatosRRHHTest(datosRRHHTest))
  }

  guardarDatosRRHHProduction = (datosRRHHProduction) => {
    this.props.dispatch(saveDatosRRHHProduction(datosRRHHProduction))
  }

  guardarDatosReportesProconsumo = (datosReportesProconsumo) => {
    this.props.dispatch(saveDatosReportesProconsumo(datosReportesProconsumo))
  }

  guardarDatosHelpdesk = (datosHelpdesk) => {
    this.props.dispatch(saveDatosHelpdesk(datosHelpdesk))
  }

  getMenuError = (error) => {
    console.log(error)
    this.setState({
      waiting: false,
      modalMensaje: true,
      modalBodyMensaje: "Error al realizar solicitud, favor espere unos minutos y reintente"
    })

  }

  compare = (a, b) => {
    if (a.NombreEmpresa < b.NombreEmpresa)
      return -1
    if (a.NombreEmpresa > b.NombreEmpresa)
      return 1
    return 0
  }

  getFormatos = () => {
    this.props.dispatch(fetchFormatos(this.props.formatos, this.getFormatosSuccess, this.getFormatosError))
  }

  getFormatosSuccess = () => {
    // do nothing
  }

  getFormatosError = (error) => {
    console.log(error)
  }

  handleGoogleLogout = () => {

  }

  renderLoading = () => {
    return (
      <div className="waiting">
        <ReactLoading className="loading" type="spokes" height="150px" width="10%" />
      </div>
    )
  }

  renderModalMensaje = () => {
    return (
      <Modal isOpen={this.state.modalMensaje} toggle={this.toggleModalMensaje} className={this.props.className}>
        <ModalHeader toggle={this.toggleModalMensaje}>Información</ModalHeader>
        <ModalBody>
          {this.state.modalBodyMensaje}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggleModalMensaje}>Cerrar</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderLoginGoogle = () => {
    return (
      <GoogleLogin
        modifier='quiet'
        clientId={googleSignInWebClientID}
        render={renderProps => (
          <Button onClick={renderProps.onClick} color="danger" className="btn-lg btn-google btn-brand border border-light"><i className="fa fa-google"></i><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ingresar con Google&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></Button>
        )}
        onSuccess={this.handleSuccessGoogleSignIn}
        onFailure={this.handleErrorGoogleSignIn}
      >
      </GoogleLogin>
    )
  }

  renderLoginPassword = () => {
    const errorEmailPassword = this.state.errorEmailPassword
    const onDismiss = () => this.setErrorEmailPassword(false)
    return (
      <Form>
        <FormGroup row>
          <Col sm={12}>
            <Label for="email">Correo Electrónico</Label>
            <Input type="email" name="email" id="email" onChange={this.handleChangeEmail} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={12}>
            <Label for="passord">Contraseña</Label>
            <Input type="password" name="password" id="password" onChange={this.handleChangePassword} />
          </Col>
        </FormGroup>
        <Alert color="danger" isOpen={errorEmailPassword} toggle={onDismiss}>
          {this.state.errorEmailPasswordText}
        </Alert>
        {/* <Button className="btn-lg" color="primary" onClick={(e) => this.doLoginEmailPassword()}>Ingresar</Button> */}
        <div style={{ textAlign: "center" }}>
          <Button onClick={(e) => this.doLoginEmailPassword()} color="primary" className="btn-lg  btn-google btn-brand"><i className="fa fa-sign-in"></i><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ingresar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></Button>
        </div>
        <br />
        <br />
      </Form>
    )
  }


  renderModal = () => {
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop="static">
        <ModalHeader toggle={this.toggle}>Ingresar por código de verificación</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Col sm={12}>
                <Label for="email">Ingresar por medio de un Código</Label>
              </Col>
            </FormGroup>
            <FormGroup row>
              <br></br>
              <Col sm={10}>
                <Label for="txtmail">Correo Electrónico</Label>
                <Input type="mail" name="txtmail" maxlength="50" id="txtmail" placeholder="Ingrese un Correo Electronico" onChange={this.handleChangeEmailtoken} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                <Label for="email">{this.state.mmCorreo}</Label>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={10}>
                <Button onClick={(e) => this.enviarCorreo(e)} color="danger" ><span>Enviar Código</span></Button>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                <Label for="txtcodigo">Código</Label>
                <Input type="number" name="txtcodigo" maxlength="10" id="txtcodigo" placeholder="ingrese el código que recibio en su correo" onChange={this.handleChangeCodigoToken} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={10}>
                <Label for="email">{this.state.mmCodigo}</Label>
              </Col>
            </FormGroup>
            <br />
            {this.state.ingresandoPorCodigo &&
              <FormGroup row>
                <Col sm={10}>
                  <div className="waiting">
                    <ReactLoading className="" type="spokes" color="#000000" height="150px" width="10%" />
                  </div>
                </Col>
              </FormGroup>
            }
            <br />
            <FormGroup row>
              <Col sm={10}>
                <Button onClick={(e) => this.ingresarPorCodigo(e)} color="danger" ><span>Ingresar</span></Button>
              </Col>
            </FormGroup>

          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggle}>Cerrar</Button>
        </ModalFooter>
      </Modal>
    )
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      mmCodigo: "",
      mmCorreo: "",
    });
  }

  renderLogin = () => {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="4">
              <CardGroup>
                <Card className="bg-success">

                  <CardBody>
                    {this.state.habilitarLoginPassword &&
                      this.renderLoginPassword()
                    }
                    <div style={{ textAlign: "center" }}>
                      {this.renderLoginGoogle()}
                      <br />
                      <br />
                      <MicrosoftLogin clientId={microsoftClienteId} authCallback={this.LoginMicrosoft} >
                        {/* <button>Iniciar con  Microsoft</button> */}
                        <Button color="danger" className="btn-lg btn-google btn-brand border border-light"><i className="fa fa-windows"></i><span>&nbsp;&nbsp;&nbsp;Ingresar con Microsoft&nbsp;&nbsp;&nbsp;</span></Button>

                      </MicrosoftLogin>
                      <br></br>
                      {
                        <Button onClick={this.toggle} color="danger" className="btn-lg btn-google btn-brand border border-light"><i className="fa fa-key"></i><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ingresar con código&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></Button>
                      }
                      {this.renderModal()}
                      <br />
                      <br />
                    </div>

                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderLogin()}
        {this.state.waiting &&
          this.renderLoading()
        }
        {this.renderModalMensaje()}


      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    formatos: state.formatos,
  }
}

export default connect(mapStateToProps)(Login)