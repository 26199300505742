export function saveDatosRRHHTest(datosRRHHTest) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_DATOS_RRHH_TEST",
      payload: {
        datosRRHHTest: datosRRHHTest
      }
    })
  }
}

export function saveDatosRRHHProduction(datosRRHHProduction) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_DATOS_RRHH_PRODUCTION",
      payload: {
        datosRRHHProduction: datosRRHHProduction
      }
    })
  }
}