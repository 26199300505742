export default function reducer(state = {
  datosHelpdesk: undefined
}, action) {
  switch (action.type) {
    case "SAVE_DATOS_HELPDESK": {
      state = { ...state, ...action.payload }
      break
    }
    default: {

    }
  }
  return state
}