export default function reducer(state = {
  proveedoresPorConfirmar: undefined,
  lastFetched: undefined
}, action) {
  switch (action.type) {
    case "LOAD_PROVEEDORES_POR_CONFIRMAR": {
      state = { ...state, ...action.payload }
      break
    }
    default: {

    }
  }
  return state
}