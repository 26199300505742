export function saveUser(user) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_USER",
      payload: {
        user: user
      }
    })
  }
}

export function saveUltimaUnidadEnEstadoServidores(ultimaUnidadEnEstadoServidores) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_EN_ESTADO_SERVIDORES",
      payload: {
        ultimaUnidadEnEstadoServidores: ultimaUnidadEnEstadoServidores
      }
    })
  }
}

export function saveUltimaUnidadEnPedidosEnLinea(ultimaUnidadEnPedidosEnLinea) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_EN_PEDIDOS_EN_LINEA",
      payload: {
        ultimaUnidadEnPedidosEnLinea: ultimaUnidadEnPedidosEnLinea
      }
    })
  }
}

export function saveUltimaUnidadEnRecurrenciasMensuales(ultimaUnidadEnRecurrenciasMensuales) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_EN_RECURRENCIAS_MENSUALES",
      payload: {
        ultimaUnidadEnRecurrenciasMensuales: ultimaUnidadEnRecurrenciasMensuales
      }
    })
  }
}

export function saveUltimaUnidadEnEvaluaciones(ultimaUnidadEnEvaluaciones) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_EN_EVALUACIONES",
      payload: {
        ultimaUnidadEnEvaluaciones: ultimaUnidadEnEvaluaciones
      }
    })
  }
}

export function saveUltimaUnidadEnErroresApp(ultimaUnidadEnErroresApp) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_EN_ERRORES_APP",
      payload: {
        ultimaUnidadEnErroresApp: ultimaUnidadEnErroresApp
      }
    })
  }
}
export function saveUltimaUnidadEnPedidosPorConfirmar(ultimaUnidadEnPedidosPorConfirmar) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_PEDIDOS_POR_CONFIRMAR",
      payload: {
        ultimaUnidadEnPedidosPorConfirmar: ultimaUnidadEnPedidosPorConfirmar
      }
    })
  }
}
export function saveUltimaUnidadPedidosPorAprobar(ultimaUnidadPedidosPorAprobar) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_EN_PEDIDOS_POR_APROBAR",
      payload: {
        ultimaUnidadPedidosPorAprobar: ultimaUnidadPedidosPorAprobar
      }
    })
  }
}
export function saveUltimaUnidadEnPaquetes(ultimaUnidadEnPaquetes) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_EN_PAQUETES",
      payload: {
        ultimaUnidadEnPaquetes: ultimaUnidadEnPaquetes
      }
    })
  }
}
export function saveUltimaUnidadEnPedidosPendientesPorAprobar(ultimaUnidadEnPedidosPendientesPorAprobar) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_PEDIDOS_PENDIENTES_POR_APROBAR",
      payload: {
        ultimaUnidadEnPedidosPendientesPorAprobar: ultimaUnidadEnPedidosPendientesPorAprobar
      }
    })
  }
}
export function saveUltimaUnidadOrdenesIncompletas(ultimaUnidadOrdenesIncompletas) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_ORDENES_INCOMPLETAS",
      payload: {
        ultimaUnidadOrdenesIncompletas: ultimaUnidadOrdenesIncompletas
      }
    })
  }
}
export function saveUltimaUnidadEnMailMasivo(ultimaUnidadEnMailMasivo) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_EN_MAIL_MASIVO",
      payload: {
        ultimaUnidadEnMailMasivo: ultimaUnidadEnMailMasivo
      }
    })
  }
}
export function saveUltimaUnidadEnWhatsappMasivo(ultimaUnidadEnWhatsappMasivo) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_EN_WHATSAPP_MASIVO",
      payload: {
        ultimaUnidadEnWhatsappMasivo: ultimaUnidadEnWhatsappMasivo
      }
    })
  }
}
export function saveUltimaUnidadValidacionCorreo(ultimaUnidadValidacionCorreo) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_VALIDACION_CORREO",
      payload: {
        ultimaUnidadValidacionCorreo: ultimaUnidadValidacionCorreo
		}
    })
  }
}
export function saveUltimaUnidadLogsPushNotificacion(ultimaUnidadLogsPushNotificacion) {
  return function (dispatch) {
    dispatch({
      type: "SAVE_ULTIMA_UNIDAD_LOGS_PUSH_NOTIFICACION",
      payload: {
        ultimaUnidadLogsPushNotificacion: ultimaUnidadLogsPushNotificacion

      }
    })
  }
}