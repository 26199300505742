// Redux
import { applyMiddleware, compose, createStore } from 'redux'

import reducer from "../reducers"

import { createLogger } from 'redux-logger'
import persistState from 'redux-localstorage'
import promise from "redux-promise-middleware"
import thunk from "redux-thunk"

let middleware = null
if (window.location.hostname === 'localhost') {
    middleware = applyMiddleware(promise(), thunk, createLogger())
}else{
    middleware = applyMiddleware(promise(), thunk)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(middleware, persistState())

export default createStore(reducer, enhancer)