export default function reducer(state = {
  cierres: undefined,
  lastFetched: undefined
}, action) {
  switch (action.type) {
      case "SAVE_CIERRES": {
          state = { ...state, ...action.payload }
          break
      }
      default: {

      }
  }
  return state
}