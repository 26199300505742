export default function reducer(state = {
    menu: {
        menuFinal: undefined
    }

}, action) {
    switch (action.type) {
        case "SAVE_MENU": {
            state = { ...state, ...action.payload }
            break
        }
        default: {

        }
    }
    return state
}