
export default function reducer(state = {
    user: undefined,
    ultimaUnidadEnEstadoServidores: -1,
    ultimaUnidadEnPedidosEnLinea: -1,
    ultimaUnidadEnRecurrenciasMensuales: -1,
    ultimaUnidadEnEvaluaciones: -1,
    ultimaUnidadEnErroresApp: -1,
    ultimaUnidadOrdenesIncompletas: -1,
    ultimaUnidadEnMailMasivo: -1,
    ultimaUnidadEnWhatsappMasivo: -1,
    ultimaUnidadValidacionCorreo: -1,
    ultimaUnidadLogsPushNotificacion: -1

}, action) {
    switch (action.type) {
        case "SAVE_USER": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_EN_ESTADO_SERVIDORES": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_EN_PEDIDOS_EN_LINEA": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_EN_RECURRENCIAS_MENSUALES": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_EN_EVALUACIONES": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_EN_ERRORES_APP": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_PEDIDOS_POR_CONFIRMAR": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_EN_PEDIDOS_POR_APROBAR": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_EN_PAQUETES": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_PEDIDOS_PENDIENTES_POR_APROBAR": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_ORDENES_INCOMPLETAS": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_EN_MAIL_MASIVO": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_EN_WHATSAPP_MASIVO": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_VALIDACION_CORREO": {
            state = { ...state, ...action.payload }
            break
        }
        case "SAVE_ULTIMA_UNIDAD_LOGS_PUSH_NOTIFICACION": {
            state = { ...state, ...action.payload }
            break
        }
        default: {

        }
    }
    return state
}